import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { updateParentAddress } from '../../../../actions/action_parent';

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const CancelLink = styled(Link)`
  display: inline-block;
  margin-left: 8px;
`;

const OverrideButton = styled.button`
  margin-top: 8px;
`;

const states = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
  'PR',
];

const CustomerAddressEdit = ({ history }) => {
  const {
    address: { address1, address2, city, state, zipcode },
    email,
    loading,
    publicToken,
  } = useSelector((state) => state.parent);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [suggestedAddress, setSuggestedAddress] = useState(null);
  const [submittedAddress, setSubmittedAddress] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = async (
    data,
    skipVerification = false,
    overrideSuggestion = false,
    overrideOnNoMatch = false,
  ) => {
    // handle cases where we're dealing with address_1 vs. address1, etc.

    const addressData = {
      address1: data.address1 || data.address_1,
      address2: data.address2 || data.address_2,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode || data.zip_code,
      skipVerification,
    };

    // console.log('addressData', addressData);
    // console.log('data', data);
    // console.log('suggested address:', suggestedAddress);
    // console.log('overrideSuggestion:', overrideSuggestion);
    // console.log('submittedAddress', submittedAddress);
    // console.log('++++++++++++++++++++++++++++++++');
    // console.log('++++++++++++++++++++++++++++++++');

    if (
      overrideSuggestion &&
      submittedAddress &&
      addressData.address1 == submittedAddress.address_1 &&
      addressData.address2 == submittedAddress.address_2 &&
      addressData.city == submittedAddress.city &&
      addressData.state == submittedAddress.state &&
      addressData.zipcode == submittedAddress.zipcode
    ) {
      // If the address is the same as what's already been submitted, no need to verify again
      // console.log('override is same as what has already been submitted');
      skipVerification = true;
    }

    // if (
    //   !skipVerification &&
    //   addressData.address1 === address1 &&
    //   addressData.address2 === address2 &&
    //   addressData.city === city &&
    //   addressData.state === state &&
    //   addressData.zipcode === zipcode
    // ) {
    //   // If the address is the same as the original, no need to verify again
    //   skipVerification = true;
    // }

    try {
      const response = await axios.post('/api/v2/parents/update-address', {
        address_1: addressData.address1,
        address_2: addressData.address2,
        city: addressData.city,
        state: addressData.state,
        zipcode: addressData.zipcode,
        email,
        publicToken,
        skipVerification,
        overrideSuggestion,
        overrideOnNoMatch,
      });

      const { status, submitted_address, suggested_address, message } =
        response.data;

      if (submitted_address) {
        setSubmittedAddress(submitted_address);
      }

      if (status === 'verification_check_not_active') {
        dispatch(updateParentAddress(addressData, email, publicToken));
        history.push('/v3/checkout');
      } else if (status === 'verified') {
        dispatch(updateParentAddress(addressData, email, publicToken));
        history.push('/v3/checkout');
      } else if (status === 'override') {
        dispatch(updateParentAddress(addressData, email, publicToken));
        history.push('/v3/checkout');
      } else if (status === 'suggested') {
        setSuggestedAddress(suggested_address);
        setVerificationStatus('suggested');
      } else if (status === 'unverified') {
        setVerificationStatus('unverified');
      } else if (status === 'error') {
        setVerificationStatus('error');
      }
    } catch (error) {
      console.log('error', error);
      setVerificationStatus('error');
    }
  };

  const defaultState = state || states[0];

  const getLoadingSpinnerClass = () => {
    if (loading) {
      return 'loading';
    }
    return 'hidden';
  };

  return (
    <>
      <div className={getLoadingSpinnerClass()}>
        <div className="spinner" />
      </div>
      <MainHeader>Edit Address</MainHeader>
      <Divider />
      <div className="order-checkout__main-content row">
        <div className="order-checkout__left col-xs-12 col-md-8 col-lg-8">
          <Wrapper>
            <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
              <div className="form-group">
                <label htmlFor="address1">Address</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors?.address1 ? 'is-invalid' : ''
                  }`}
                  id="address1"
                  {...register('address1', { required: 'Address is required' })}
                  defaultValue={address1}
                />
                {errors.address1 && (
                  <div className="text-danger">{errors?.address1.message}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="address2">Address Line 2 (Optional)</label>
                <input
                  type="text"
                  className="form-control"
                  id="address2"
                  {...register('address2')}
                  defaultValue={address2}
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  className={`form-control ${errors?.city ? 'is-invalid' : ''}`}
                  id="city"
                  {...register('city', { required: 'City is required' })}
                  defaultValue={city}
                />
                {errors.city && (
                  <div className="text-danger">{errors.city.message}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="state">State</label>
                <Controller
                  render={({ field }) => (
                    <select {...field} className="form-control">
                      {states.map((stateCode) => (
                        <option key={stateCode} value={stateCode}>
                          {stateCode}
                        </option>
                      ))}
                    </select>
                  )}
                  name="state"
                  control={control}
                  defaultValue={defaultState}
                />
              </div>
              <div className="form-group">
                <label htmlFor="zipcode">Zip Code</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors?.zipcode ? 'is-invalid' : ''
                  }`}
                  id="zipcode"
                  {...register('zipcode', {
                    required: 'Zip Code is required',
                    minLength: {
                      value: 5,
                      message: 'Zip Code must be at least 5 digits long',
                    },
                  })}
                  defaultValue={zipcode}
                />
                {errors.zipcode && (
                  <div className="text-danger">{errors.zipcode.message}</div>
                )}
              </div>
              {!suggestedAddress &&
                verificationStatus !== 'unverified' &&
                verificationStatus !== 'error' && (
                  <>
                    <button type="submit" className="btn btn-primary">
                      Update Address
                    </button>
                    <CancelLink to="/v3/checkout" className="ml-2">
                      Cancel
                    </CancelLink>
                  </>
                )}
            </form>

            {verificationStatus === 'suggested' && suggestedAddress && (
              <div className="suggested-address">
                <p>
                  Please consider using this suggested mailing address for
                  better accuracy and faster delivery.
                </p>
                <h5>Suggested Address</h5>
                <p>{suggestedAddress.address_1}</p>
                <p>{suggestedAddress.address_2}</p>
                <p>
                  {suggestedAddress.city}, {suggestedAddress.state}{' '}
                  {suggestedAddress.zip_code}
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleSubmit((data) => onSubmit(suggestedAddress, true))()
                  }
                >
                  Accept Suggested Address
                </button>
                <div>
                  <OverrideButton
                    className="btn btn-secondary ml-5"
                    onClick={() =>
                      handleSubmit((data) => onSubmit(data, false, true))()
                    }
                  >
                    Override and Submit My Address
                  </OverrideButton>
                </div>
              </div>
            )}

            {verificationStatus === 'unverified' && (
              <>
                <div
                  className="alert alert-warning"
                  style={{ marginTop: '16px' }}
                >
                  <p>
                    The submitted address could not be verified as a valid
                    mailing address.
                  </p>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() =>
                    handleSubmit((data) => onSubmit(data, true, false, true))()
                  }
                >
                  Update Anyway
                </button>
                <CancelLink to="/v3/checkout" className="ml-2">
                  Cancel
                </CancelLink>
              </>
            )}

            {verificationStatus === 'error' && (
              <>
                <div
                  className="alert alert-danger"
                  style={{ marginTop: '16px' }}
                >
                  <p>
                    An error occurred during address verification. Please try
                    again later or update the address without verification.
                  </p>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() =>
                    handleSubmit((data) => onSubmit(data, true, false, true))()
                  }
                >
                  Update Anyway
                </button>
                <CancelLink to="/v3/checkout" className="ml-2">
                  Cancel
                </CancelLink>
              </>
            )}
          </Wrapper>
        </div>
      </div>
    </>
  );
};

export default withRouter(CustomerAddressEdit);
