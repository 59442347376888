import React from 'react';

const Address = () => {
  const [firstName, lastName, address1, address2, city, state, zipCode] =
    React.useState('');

  const handleFirstNameChange = (event) => {
    event.preventDefault();
  };

  const handleLastNameChange = (event) => {
    event.preventDefault();
  };

  const handleAddress1Change = (event) => {
    event.preventDefault();
  };

  const handleAddress2Change = (event) => {
    event.preventDefault();
  };

  const handleCityChange = (event) => {
    event.preventDefault();
  };

  const handleStateChange = (event) => {
    event.preventDefault();
  };

  const handleZipCodeChange = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform any necessary logic or API calls with the updated username and email
    // ...

    // Reset the input fields
  };

  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <div className="page-header">
          <h1>Update Your Address</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Address Line 1</label>
            <input
              type="text"
              className="form-control"
              value={address1}
              onChange={handleAddress1Change}
            />
          </div>
          <div className="form-group">
            <label>Address Line 2</label>
            <input
              type="text"
              className="form-control"
              value={address2}
              onChange={handleAddress2Change}
            />
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={city}
                  onChange={handleCityChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>State</label>
                <input
                  type="text"
                  className="form-control"
                  value={state}
                  onChange={handleStateChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>ZipCode</label>
                <input
                  type="text"
                  className="form-control"
                  value={zipCode}
                  onChange={handleZipCodeChange}
                />
              </div>
            </div>
          </div>
          <br />
          <button type="submit" className="btn btn-primary pull-right">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Address;
