import React from 'react';
import moment from 'moment';

function BnlDashboardNotice(props) {
  const NOTICE_START_DAY = '2023-11-11';
  const NOTICE_LAST_DAY = '2024-01-03';

  const { companyAuthToken } = props;

  // TODO: store this config in the database
  const companiesWithHolidayMessage = [
    'jellyjar',
    'gb',
    'bnl',
    'normandy',
    'tipping',
    'marcel',
    'classact',
    'bielmar',
    'wagner',
  ];

  const dashboardNoticeStyle = {
    background: '#ffffff',
    borderRadius: '5px',
    marginBottom: '25px',
    padding: '25px 25px 10px 25px',
    textAlign: 'center',
  };

  const noticeHeaderStyle = {
    marginTop: '0',
    marginBottom: '15px',
    fontStyle: 'italic',
  };

  const noticeParagraphStyle = {
    marginBottom: '15px',
  };

  if (
    moment().isAfter(NOTICE_START_DAY, 'day') &&
    moment().isBefore(NOTICE_LAST_DAY, 'day') &&
    companiesWithHolidayMessage.includes(companyAuthToken)
  ) {
    return (
      <div
        className="bnl-gallery-dashboard-notice"
        style={dashboardNoticeStyle}
      >
        <h3 style={noticeHeaderStyle}>Holiday Delivery Notice</h3>
        <p style={noticeParagraphStyle}>
          Please place your order&nbsp;
          <strong>BEFORE</strong>
          &nbsp;December 5th to ensure you receive your products before 12/23.
        </p>
        <p style={noticeParagraphStyle}>
          All orders placed after 12/5 will be processed but may not arrive
          prior to 12/23.
        </p>
        <p style={noticeParagraphStyle}>
          All orders placed 12/23 through 1/2 will be processed and shipped
          1/3/24.
        </p>
      </div>
    );
  }
  return null;
}

export default BnlDashboardNotice;
