import React, { Component } from 'react';

class ParentMyAccount extends Component {
  constructor(props) {
    super(props);

    this.navigateTo = this.navigateTo.bind(this);
  }

  navigateTo(url) {
    this.props.history.push(`/my-account/${url}`);
  }

  render() {
    return <div>ParentMyAccount Component</div>;
  }
}

export default ParentMyAccount;
