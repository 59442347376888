/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Background from './background';
import Pose from './pose';
import Product from './product';
import Personalization from './personalization';
import BackLinkFromYPX from '../shared/BackLinkFromYPX.jsx';

const OrderRouter = () => (
  <div>
    <BackLinkFromYPX />
    <Route path="/v3/products/:id/background/:number" component={Background} />
    <Route path="/v3/products/:id/pose/:number" component={Pose} />
    <Route path="/v3/products/:id/product/:number" component={Product} />
    <Route
      path="/v3/products/:id/personalization/:number"
      component={Personalization}
    />
  </div>
);

export default withRouter(OrderRouter);
