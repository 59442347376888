import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { setAuthToken, setCurrentUser } from '../actions/action_auth';
import { setCompanyName } from '../actions/action_company';
import { setCurrentLocation } from '../actions/action_location';
import { getParentData, getStudents } from '../actions/action_parent';
import OrderRouter from './v3/productConfig';

// React.js components
import Address from './account/Address';
import Details from './account/Details';
import LoginRedirect from './auth/LoginRedirect';
import NewParentSignupFormContainer from './auth/NewParentSignupFormContainer';
import NewPasswordLookupForm from './auth/NewPasswordLookupForm';
import ParentAuthContainer from './auth/parent_auth_container';
import AccountLookupForm from './auth/parent_lookup_form';
import ParentSignupFormContainer from './auth/parent_signup_form_container';
import ErrorBoundary from './error_boundary';
import Navbar from './navbar/navbar';
import FindKey from './orders/find_key';
import OrderContainer from './orders/order_container';
import BnlParentClickAndSafe from './parents/bnl/bnl_parent_click_and_safe';
import BnlParentDashboard from './parents/bnl/bnl_parent_dashboard';
import BnlParentStudentPreShootDetailForm from './parents/bnl/bnl_parent_student_preshoot_detail_form';
import BnlStudentSelection from './parents/bnl/bnl_parent_student_selection';
import BnlParentYearbookPhotoSelection from './parents/bnl/bnl_parent_yearbook_photo_selection';
import ParentAddStudentForm from './parents/parent_add_student_form';
import ParentMyAccount from './parents/parent_my_account';
import ParentMyOrders from './parents/parent_my_orders';
import Notification from './utils/notification';
import Cart from './v3/cart/Cart';
import Category from './v3/categories/Category';
import Offerings from './v3/categories/Offerings';
import Checkout from './v3/checkout/Checkout';
import CustomerAddressEdit from './v3/checkout/subcomponents/CustomerAddressEdit';
import IncentiveOffers from './v3/incentives/IncentiveOffers';
import PersonalizeProduct from './v3/products/personalization/PersonalizeProduct';
import RetouchingConfig from './v3/products/retouching/RetouchingConfig';
import SentryTesting from './v3/utils/SentryTesting';
import VenueLookupByZipcode from './venue-lookup/VenueLookupByZipcode';
/* TODO: remove these testing components when testing is complete */
import NthFreeQualification from './utils/NthFreeQualification';
import PreCartOffers from './utils/PreCartOffers';
import PreDashboardOffers from './utils/PreDashboardOffers';
import ScrollToTop from './utils/ScrollToTop';
import CheckoutSuccess from './v3/checkout/CheckoutSuccess';
import ClearConfig from './v3/hooks/clearConfig';
import V3Layout from './v3/layouts/V3Layout';
import LookupByVenueAndStudentId from './v3/lookups/LookupByVenueAndStudentId';
import ProductConfig from './v3/products/ProductConfig';
import IncentivesConfig from './v3/products/incentive/IncentivesConfig';
import InterstitialConfig from './v3/products/interstitial/InterstitialConfig';
import PersonalizationOffer from './v3/products/personalization/PersonalizationOffer';
import PreCartOfferConfig from './v3/products/pre-cart/PreCartOfferConfig';
import SuggestedProducts from './v3/products/suggested/SuggestedProducts';
import TermsConditions from './v3/terms/TermsConditions';
import UnsubscribeMessage from './v3/unsubscribe/UnsubscribeMessage';
import UnsubscribeReasons from './v3/unsubscribe/UnsubscribeReasons';
import DashboardRedirect from './v3/utils/DashboardRedirect';
import SelectYearbookBackground from './v3/yearbook/SelectYearbookBackground';
import SelectYearbookComplete from './v3/yearbook/SelectYearbookComplete';
import SelectYearbookPhoto from './v3/yearbook/SelectYearbookPhoto';
import PrivateCheckoutRoute from '../routes/PrivateCheckoutRoute';
import CustomerAddressConfirmation from './v3/address/CustomerAddressConfirmation';

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const that = this;
    const token = $('meta[name=csrf-token]').attr('content');
    const { history, location } = this.props;

    // TODO: can we remove this??
    this.props.setCurrentUser(token).then((response) => {
      if (response?.payload?.data) {
        if (response.payload.data.currentUserId) {
          that.props.getParentData();
          that.props.getStudents(response.payload.data.currentUserId);
        }
      }
    });

    // TODO: remove this when confirmed that it is not needed
    // this.props.setCompanyName(this.props.css);

    Sentry.setUser({
      email: this.props.auth.currentUserEmail,
      id: this.props.auth.currentUserId,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.setCurrentLocation(this.props.location.pathname);
    }
  }

  render() {
    const { history, location } = this.props;
    const uiVersion = this.props.ui_version;

    // TODO: determine what we're ultimately doing with this... can we remove it?
    const companyName = this.props?.css;
    const eventLookupByZipcodeEnabled =
      this.props?.event_lookup_by_zipcode_enabled;

    if (!companyName) return <div className="loading" />;
    const companyAuthToken = this.props.auth_token;

    const PrivateRoute = ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            this.props.auth.currentUserId && this.props.auth.signupCompleted ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login-redirect',
                  state: { className: companyName, reloadRequired: true },
                }}
              />
            )
          }
        />
      );
    };

    // This component only redirects when the user is already logged in, seeminly
    // due to the Dashboard being re-rendered more than once
    // TODO: remove if/when the Dashboard re-render issue is resolved
    const PreDashboardRedirect = ({ children }) => {
      console.log('👉 pre-dash redirect check');

      const activeRedirect = Cookies.get('active_redirect');

      // TODO: move this to a helper function
      const isLoggedIn =
        this.props.auth.currentUserId && this.props.auth.signupCompleted;

      if (isLoggedIn && activeRedirect === 'lookup-by-id') {
        console.log('>> redirecting to lookup-by-student-id...');

        history.push('/v3/lookup-by-student-id');
        return null;
      }

      return children;
    };

    const PrivateRouteWithV3Layout = ({ component: Component, ...rest }) => {
      const showStudentBanner = rest.showStudentBanner ?? true;

      return (
        <Route
          {...rest}
          render={(props) =>
            this.props.auth.currentUserId && this.props.auth.signupCompleted ? (
              <V3Layout showStudentBanner={showStudentBanner}>
                <Component {...props} />
              </V3Layout>
            ) : (
              <Redirect
                to={{
                  pathname: '/login-redirect',
                  state: { className: companyName, reloadRequired: true },
                }}
              />
            )
          }
        />
      );
    };

    let dashboard;

    // switch (companyName) {
    //   // Move V1 Geskus UI to V2 Pics UI
    //   case 'geskusprint':
    //     if (uiVersion === 2) {
    //       dashboard = (props) => (
    //         <BnlParentDashboard
    //           history={props.history}
    //           key="dashboard"
    //           companyName={companyName}
    //           companyAuthToken={companyAuthToken}
    //         />
    //       );
    //     } else {
    //       dashboard = (props) => (
    //         <ParentDashboard history={props.history} key="dashboard" />
    //       );
    //     }
    //     break;
    //   default:
    //     dashboard = (props) => (
    //       <BnlParentDashboard
    //         history={props.history}
    //         key="dashboard"
    //         companyName={companyName}
    //         companyAuthToken={companyAuthToken}
    //       />
    //     );
    // }

    dashboard = (props) => (
      <BnlParentDashboard
        history={props.history}
        key="dashboard"
        companyName={companyName}
        companyAuthToken={companyAuthToken}
        eventLookupByZipcodeEnabled={eventLookupByZipcodeEnabled}
      />
    );

    const MyOrderContainer = (props) => (
      <ErrorBoundary>
        <OrderContainer
          history={props.history}
          stripePublishableKey={this.props.stripe_publishable_key}
        />
      </ErrorBoundary>
    );

    const MyNavbar = (props) => (
      <Navbar companyName={companyName} history={props.history} />
    );

    // TODO: remove test routes when testing is complete
    return (
      <Router history={history}>
        <div id="app" className={`app--${companyName}`}>
          <Notification />
          <ClearConfig />
          <Route component={MyNavbar} />
          <ScrollToTop>
            <div id="react-component">
              <Route
                exact
                path="/login"
                render={(props) => (
                  <ParentAuthContainer
                    companyToken={this.props.css}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/login-redirect"
                render={(props) => <LoginRedirect {...props} />}
              />
              <Route
                exact
                path="/new-login"
                render={(props) => (
                  <ParentAuthContainer
                    companyToken={this.props.css}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/account-lookup"
                render={(props) => <AccountLookupForm {...props} />}
              />
              <Route
                exact
                path="/signup"
                render={(props) => <ParentSignupFormContainer {...props} />}
              />
              <Route
                exact
                path="/new-account"
                render={(props) => <NewParentSignupFormContainer {...props} />}
              />
              <Route
                exact
                path="/password-lookup"
                render={(props) => <NewPasswordLookupForm {...props} />}
              />
              {/* <PreDashboardOffers {...props}>
                <PrivateRoute exact path="/dashboard" component={dashboard} />
              </PreDashboardOffers> */}
              <Route
                exact
                path="/dashboard"
                render={(props) => (
                  <PreDashboardRedirect>
                    <PreDashboardOffers {...props}>
                      <PrivateRoute exact component={dashboard} />
                    </PreDashboardOffers>
                  </PreDashboardRedirect>
                )}
              />
              <Route
                exact
                path="/unsubscribe-reasons"
                render={(props) => <UnsubscribeReasons {...props} />}
              />
              <Route
                exact
                path="/unsubscribe-message"
                render={(props) => <UnsubscribeMessage {...props} />}
              />
              <PrivateRoute
                exact
                path="/dashboard-redirect"
                history={history}
                component={DashboardRedirect}
              />
              <PrivateRoute exact path="/find-key" component={FindKey} />
              <PrivateRoute
                exact
                path="/venues/lookup-by-zipcode"
                component={VenueLookupByZipcode}
              />
              <PrivateRoute
                exact
                path="/select-student"
                component={BnlStudentSelection}
              />
              <PrivateRoute
                exact
                path="/v3/lookup-by-student-id"
                component={LookupByVenueAndStudentId}
              />
              <PrivateRoute
                exact
                path="/preshoot-student-form"
                component={BnlParentStudentPreShootDetailForm}
              />
              <PrivateRoute
                exact
                path="/add-student"
                component={ParentAddStudentForm}
              />
              <PrivateRoute
                exact
                path="/my-account"
                component={ParentMyAccount}
              />
              <PrivateRoute
                exact
                path="/my-account/details"
                component={Details}
              />
              <PrivateRoute
                exact
                path="/my-account/address"
                component={Address}
              />
              <PrivateRoute
                exact
                path="/yearbook-photo-selection"
                component={BnlParentYearbookPhotoSelection}
              />
              <PrivateRoute
                exact
                path="/click-and-safe"
                component={BnlParentClickAndSafe}
              />
              <PrivateRoute
                exact
                path="/my-orders"
                component={ParentMyOrders}
              />
              <PrivateRoute exact path="/order" component={MyOrderContainer} />
              <PrivateRoute
                exact
                path="/v3/yearbook-selection"
                history={history}
                component={SelectYearbookPhoto}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/terms-conditions"
                history={history}
                component={TermsConditions}
              />
              <PrivateRoute
                exact
                path="/v3/yearbook-background-selection"
                history={history}
                component={SelectYearbookBackground}
              />
              <PrivateRoute
                exact
                path="/v3/yearbook-selection-complete"
                history={history}
                component={SelectYearbookComplete}
              />
              <PrivateRoute
                exact
                path="/v3/incentive-offers"
                history={history}
                component={IncentiveOffers}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/sentry-testing"
                history={history}
                component={SentryTesting}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/categories/:id"
                history={history}
                component={Category}
              />
              <Route
                exact
                path="/v3/offers"
                render={(props) => (
                  <NthFreeQualification {...props}>
                    <PrivateRouteWithV3Layout exact component={Offerings} />
                  </NthFreeQualification>
                )}
              />
              <PrivateRouteWithV3Layout
                path="/v3/products/:id"
                component={OrderRouter}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/suggested-products"
                component={SuggestedProducts}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/products-regular/:id"
                component={ProductConfig}
              />
              {/* <PrivateRouteWithV3Layout path="/v3/cart" component={Cart} /> */}
              <Route
                exact
                path="/v3/cart"
                render={(props) => (
                  <NthFreeQualification {...props}>
                    <PreCartOffers {...props}>
                      <PrivateRouteWithV3Layout
                        exact
                        component={Cart}
                        showStudentBanner={false}
                      />
                    </PreCartOffers>
                  </NthFreeQualification>
                )}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/personalization"
                component={PersonalizeProduct}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/personalization-offer"
                history={history}
                component={PersonalizationOffer}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/incentives/config"
                component={IncentivesConfig}
                history={history}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/pre-cart-offers"
                component={PreCartOfferConfig}
                history={history}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/interstitial"
                component={InterstitialConfig}
                history={history}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/retouching"
                component={RetouchingConfig}
                history={history}
              />
              <PrivateCheckoutRoute
                exact
                path="/v3/checkout"
                component={Checkout}
                showStudentBanner={false}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/checkout/customer-address-confirmation"
                component={CustomerAddressConfirmation}
                showStudentBanner={false}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/checkout/customer-address-edit"
                component={CustomerAddressEdit}
                showStudentBanner={false}
              />
              <PrivateRouteWithV3Layout
                exact
                path="/v3/checkout-success"
                component={CheckoutSuccess}
              />
            </div>
          </ScrollToTop>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getParentData,
      getStudents,
      setAuthToken,
      setCompanyName,
      setCurrentUser,
      setCurrentLocation,
    },
    dispatch,
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
