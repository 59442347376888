import axios from 'axios';
import * as Sentry from '@sentry/react';

export const config = (clearState) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        Sentry.captureMessage('User triggered 401');
        //window.location.replace('/login');
        //clearState();
        //return;
      }
      return error;
    },
  );
};
