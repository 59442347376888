import axios from 'axios';
import { add } from 'lodash';

// export const GET_PARENT_DATA = "GET_PARENT_DATA";
export const ADD_STUDENT = 'ADD_STUDENT';
export const SET_STUDENT_DATA = 'SET_STUDENT_DATA';
export const GET_STUDENTS = 'GET_STUDENTS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const SET_SMS_PHONE = 'SET_SMS_PHONE';
export const GET_MY_ORDERS = 'GET_ORDERS';
export const SET_PARENT_DATA = 'SET_PARENT_DATA';
export const GET_PARENT_DATA = 'GET_PARENT_DATA';
export const UPDATING_PARENT_DATA = 'UPDATING_PARENT_DATA';
export const UPDATE_PARENT_ADDRESS_SUCCESS = 'UPDATE_PARENT_ADDRESS_SUCCESS';
export const UPDATE_PARENT_ADDRESS_FAILURE = 'UPDATE_PARENT_ADDRESS_FAILURE';
export const VIEWED_COMPANY_MESSAGE = 'VIEWED_COMPANY_MESSAGE';

const apiUrl = '/api/v2';

export function getParentData() {
  const request = axios.get(`${apiUrl}/parents/get_parent_data`);

  return {
    type: GET_PARENT_DATA,
    payload: request,
  };
}

export function addStudent(data) {
  const request = axios.post(`${apiUrl}/students`, data);

  return {
    type: ADD_STUDENT,
    payload: request,
  };
}

export function getStudents(parentId) {
  const request = axios.get(`${apiUrl}/parents/${parentId}/students`);

  return {
    type: GET_STUDENTS,
    payload: request,
  };
}

export function setStudentData(studentData) {
  console.log('SET STUDENT DATA', studentData);

  return {
    type: SET_STUDENT_DATA,
    payload: studentData,
  };
}

export function updateEmail(id, data) {
  const request = axios.patch(`${apiUrl}/parents/${id}`, data);

  return {
    type: UPDATE_EMAIL,
    payload: request,
  };
}

export function updateAddress(id, data) {
  const request = axios.patch(`${apiUrl}/parents/${id}/address`, data);

  return {
    type: UPDATE_ADDRESS,
    payload: request,
  };
}

// TODO: Remove this function
// export const updateParentAddress = (addressData, email, publicToken) => {
//   console.log(addressData, email, publicToken);
//   const { address1, address2, city, state, zipcode } = addressData;

//   return async (dispatch) => {
//     dispatch({
//       type: UPDATING_PARENT_DATA,
//     });

//     try {
//       const response = await axios.post('/api/v2/parents/update-address', {
//         address_1: address1,
//         address_2: address2,
//         city,
//         state,
//         zipcode,
//         email,
//         publicToken,
//       });

//       let addressData = response.data.address;

//       dispatch({
//         type: UPDATE_PARENT_ADDRESS_SUCCESS,
//         payload: { addressData },
//       });
//     } catch (error) {
//       dispatch({
//         type: UPDATE_PARENT_ADDRESS_FAILURE,
//         payload: error.message,
//       });
//     }
//   };
// };

export function updateParentAddress(addressData, email, publicToken) {
  return {
    type: UPDATE_PARENT_ADDRESS_SUCCESS,
    payload: addressData,
  };
}

export function setSmsPhone(smsPhone) {
  return {
    type: SET_SMS_PHONE,
    payload: smsPhone,
  };
}

export function getMyOrders(parentId) {
  const request = axios.get(`${apiUrl}/parents/${parentId}/orders`);

  return {
    type: GET_MY_ORDERS,
    payload: request,
  };
}

export function viewedCompanyMessage() {
  return {
    type: VIEWED_COMPANY_MESSAGE,
    payload: true,
  };
}
